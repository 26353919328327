import request from '@/utils/request'

export default {

  // 代理商查询
  agentSearch: function(params) {
    return request({
      url: '/api/agent/query',
      method: 'get',
      params:params
    })
  },

}
